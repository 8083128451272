@import './common/Breakpoints';

.slider-items-container {
    border-radius: .25rem;
    padding-bottom: 10px;
}

.slider-outer-wrapper {
    position: relative;
}

.slider-right-enter,
.slider-left-enter {
    max-height: 100%;
    overflow: hidden;
    width: 100%;
    position: absolute;
}

.slider-right-enter:global(-active),
.slider-left-enter:global(-active),
.slider-right-leave:global(-active),
.slider-left-leave:global(-active) {
    transition: all .3s cubic-bezier(0.345, 0.330, 0.000, 1.000);
}

.slider-right-enter,
.slider-left-leave:global(-active) {
    transform: translate(100%, 0);
}

.slider-left-enter,
.slider-right-leave:global(-active) {
    transform: translate(-100%, 0);
}

.slider-right-enter:global(-active),
.slider-left-enter:global(-active) {
    transform: translate(0, 0);
}

@media (max-width: $xs-max) {
    .slider-transition-item {
        .form-control {
            text-align: right;
            font-size: 16px;
            font-weight: bold;
            padding-right: 20px;
            color: #333;
        }

        .slide-transition-item-component-div {
            padding-right: 0px;
        }

        :global(.input-label) {
          font-size: 18px;
          background-color: transparent;
          padding: 0px;
          margin-left: 20px;
          -webkit-align-self: center;
          align-self: center;
        }

    }

    .input-group {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }

    .slide-transition-item-component-div {
        padding-right: 0px;
    }

    .slide-transition-item-component-div  div{
        width: 100%;
        text-align: right;
        font-size: 16px;
        font-weight: bold;
    }
}

.slide-transition-item-error {
    border-top: solid 1px #a94442 !important;
    border-bottom: solid 1px #a94442 !important;

    .input-group-addon {
        background: #f2dede;
        color: #a94442;
    }
}


.slider-transition-item {
    :global(.input-group) {
        display: -webkit-flex;
        display: flex;
        border-top: solid 1px #CCCCCC;
        border-bottom: solid 1px #CCCCCC;
    }

    .form-control-feedback {
        top: 8px;
    }

    .form-control {
        box-shadow: none;
        background: transparent;
        height: 72px;
        border: none;

        &:focus {
            border: 2px solid #666;
        }
    }

    .input-group-addon {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        border: none;
    }

    .input {
        -webkit-flex-grow: 1;
        flex-grow: 1;
        width: 0;
    }

    .arrow-svg {
        color: #333333;
        -webkit-align-self: center;
        align-self: center;
        margin-right: 10px;
    }

    .slide-transition-item-loading-div {
        line-height: 72px;
        & > span:first-child {
            margin-left: 7px;
            margin-right: 7px;
            svg {
                position: absolute;
            }
        }
    }

    .slide-transition-item-component-div {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 17px 20px 17px 10px;
    }

    .slide-transiton-item-error-div {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    margin-bottom: 0;
}