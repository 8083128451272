@import 'common/Breakpoints.scss';

.ios-scrolling {
    -webkit-overflow-scrolling: touch; /* lets it scroll lazy */
}

:global(.intl-phone-number) {
    display: flex;
    align-items: flex-start;

    :global(.input-parent-container) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    :global(.input-container) {
        flex: auto;
        margin-left: 10px;

        :global(input.form-control) {
            height: 35px;
            padding: 0;
            border: none;
            box-shadow: none;
            &:focus {
                border: 2px solid #666;
                height: 42px;
            }
        }
    }

    :global(.delete-button-container) {
        cursor: pointer;
        height: 42px;
        width: 42px;
        flex: none;
    }

    :global(.delete-button-link) {
        height: 100%;
        width: 100%;
        display: inline-block;
    }

    // Override default dropdown container class that will cause the input to float to the next line.
    :global(.row.form-group.form-control) {
        :global(.selection.dropdown.btn-group) {
            :global(button.dropdown-toggle.btn.btn-sm) {
                font-size: 16px;
            }
        }
    }
}

//// Limiting the max width of the input only makes sense at true mobile browser widths < 400px.
//@media(max-width: 400px) {
//    // Prevent the input from floating underneath the container in smaller breakpoints.
//    :global(.input-container) {
//        max-width: 125px;
//    }
//}
.intnl-ph-no-fld-form-group {
    margin-bottom: 0;
    margin-left:0 !important;
    width: 100%;
    display: flex !important;
    align-items: center;
    height: 42px;
    padding: 2.5px 0 2.5px 2.5px !important;
}

.intnl-ph-no-fld-dropdown-menu-li {
    padding: 0 !important;
    width: 100%;
}

.intnl-ph-no-list-item-div {
    overflow: auto;
    width: 100%;
}

.intnl-ph-no-fld-form-group div:global(.selection.dropdown.btn-group) div:global(.dropdown-menu) {
    margin-top: 2.5px;
}

.intnl-ph-no-fld-form-group ul {
    height: 298px;
}

.intnl-ph-no-new-flag {
    background: url('../img/flag-sprites.png');
    overflow: hidden;
    width: 48px;
    height: 50px;
    float: left;
}

.intnl-ph-no-name {
    padding-left: 10px;
    padding-top: 5px;
    float: left;
    width: calc(100% - 48px - 64px);
    white-space: normal;
}

.intnl-ph-no-code {
    display: inline-block;
    padding-top: 5px;
    padding-left: 10px;
    float: right;
    width: 64px;
}

.error-container {
    margin-bottom: 0;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CA {
    background-position: -348px -199px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-XK {
    background-position: -498px -1px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CM {
    background-position: -149px -250px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CV {
    background-position: -398px -249px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-KY {
    background-position: -48px -547.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-WB {
    background-position: -300px -750.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TD {
    background-position: -500px -849.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AD {
    background-position: -449px -50px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AE {
    background-position: -499px -50px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AF {
    background-position: -549px -50px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AG {
    background-position: 0 -99px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AI {
    background-position: -49px -99px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AL {
    background-position: -100px -100px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AM {
    background-position: -149px -100px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AO {
    background-position: -249px -100px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AQ {
    background-position: -299px -100px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AR {
    background-position: -349px -99px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AS {
    background-position: -398px -99px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AT {
    background-position: -448px -99px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AU {
    background-position: -498px -100px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AW {
    background-position: -548px -100px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-AZ {
    background-position: 0 -149px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BA {
    background-position: -49px -149px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BB {
    background-position: -99px -149px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BD {
    background-position: -149px -150px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BE {
    background-position: -198px -150px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BF {
    background-position: -249px -149px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BG {
    background-position: -299px -150px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BH {
    background-position: -349px -149px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BI {
    background-position: -399px -150px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BJ {
    background-position: -448px -149px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BM {
    background-position: -498px -150px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BN {
    background-position: -548px -150px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BO {
    background-position: 0 -199px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BR {
    background-position: -49px -199px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BS {
    background-position: -99px -199px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BT {
    background-position: -149px -199px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BW {
    background-position: -199px -199px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BY {
    background-position: -249px -200px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BZ {
    background-position: -298px -199px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CG, .intnl-ph-no-new-flag.intnl-ph-no-new-flag-CD {
    background-position: -398px -199px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CF {
    background-position: -448px -199px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CG {
    background-position: -498px -199px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CI {
    background-position: -1px -250px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CL {
    background-position: -99px -249px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CN {
    background-position: -199px -249px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CO {
    background-position: -249px -249px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CR {
    background-position: -299px -249px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CU {
    background-position: -349px -249px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CY {
    background-position: -448px -249px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CZ {
    background-position: -498px -249px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-DJ {
    background-position: 0 -299px ;
}


.intnl-ph-no-new-flag.intnl-ph-no-new-flag-DK{
    background-position: -50px -299px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-DM{
    background-position: -99px -299px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-DO {
    background-position: -149px -299px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-DZ {
    background-position: -199px -299px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-EC {
    background-position: -249px -298px ;
}


.intnl-ph-no-new-flag.intnl-ph-no-new-flag-EE {
    background-position: -299px -299px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-EG {
    background-position: -349px -299px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-ER {
    background-position: -448px -299px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-ES {
    background-position: -498px -299px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-ET {
    background-position: -548px -299px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-FJ {
    background-position: -50px -349px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-FM {
    background-position: -100px -349px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-FO {
    background-position: -149px -349px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-BL, .intnl-ph-no-new-flag.intnl-ph-no-new-flag-FR {
    background-position: -200px -349px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GA {
    background-position: -250px -349px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GB {
    background-position: -299px -349px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GD {
    background-position: -349px -349px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GE {
    background-position: -399px -349px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GH {
    background-position: -499px -349px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GI {
    background-position: -549px -349px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-FI {
    background-position: 0 -349.00001525878906px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-DE {
    background-position: -551px -251px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CH {
    background-position: -550px -200px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-CK {
    background-position: -50px -251px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GL {
    background-position: -1px -398.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GN {
    background-position: -100.00001525878906px -398.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GP {
    background-position: -149px -398px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GQ {
    background-position: -199px -397px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GR {
    background-position: -248px -398px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GT {
    background-position: -298px -397px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GU {
    background-position: -348px -397px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GW {
    background-position: -397px -397px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GY {
    background-position: -447px -397px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-HK {
    background-position: -497px -397px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-HN {
    background-position: -547px -398px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-HR {
    background-position: -1px -448.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-HT {
    background-position: -50px -449.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-ID {
    background-position: -151.00001525878906px -448.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-IL {
    background-position: -249px -447.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-IM {
    background-position: -299.0000305175781px -448.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-IN {
    background-position: -350.0000305175781px -448.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-IQ {
    background-position: -399.0000305175781px -448.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-IR {
    background-position: -450px -447.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-IS {
    background-position: -500px -448.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-IT {
    background-position: -548px -448.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-IE {
    background-position: -199px -447.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-HU {
    background-position: -100.00001525878906px -448.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-GM {
    background-position: -50px -398.0000228881836px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-JM {
    background-position: -49px -498.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-JO {
    background-position: -99.00001525878906px -498.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-JP {
    background-position: -149.00001525878906px -498.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-KE {
    background-position: -200px -498.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-KG {
    background-position: -250px -497.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-KH {
    background-position: -299.0000305175781px -498.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-KI {
    background-position: -351.0000305175781px -498.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-KM {
    background-position: -401.0000305175781px -498.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-KN {
    background-position: -451px -497.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-KP {
    background-position: -501px -497.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-KR {
    background-position: -549px -498.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-KW {
    background-position: 0 -547.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-KZ {
    background-position: -100.00001525878906px -548.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-LA {
    background-position: -148.00001525878906px -548.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-LB {
    background-position: -199px -548.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-LC {
    background-position: -250px -549.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-LI {
    background-position: -299.0000305175781px -547.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-LK {
    background-position: -350.0000305175781px -547.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-LR {
    background-position: -399.0000305175781px -548.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-LT {
    background-position: -499px -547.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-LU {
    background-position: -548px -547.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-LV {
    background-position: -1px -600.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-LY {
    background-position: -51px -600.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MA {
    background-position: -100px -599.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MC {
    background-position: -151px -600.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-ME {
    background-position: -251px -600.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MG {
    background-position: -301px -600.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MH {
    background-position: -352px -599.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MK {
    background-position: -401px -600.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-ML {
    background-position: -449px -599.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MM {
    background-position: -500px -599.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MN {
    background-position: -551px -598.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MR {
    background-position: -100px -650.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MS {
    background-position: -150px -650.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MT {
    background-position: -201px -650.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MU {
    background-position: -251px -650.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MV {
    background-position: -300px -650.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MW {
    background-position: -351px -650.0000152587891px ;
}

.intnl-ph-no-new-flag.flag-Mexico-landline, .intnl-ph-no-new-flag.intnl-ph-no-new-flag-MX {
    background-position: -399px -650.0000152587891px ;
}

.intnl-ph-no-new-flag.flag-Mexico-landline, .intnl-ph-no-new-flag.intnl-ph-no-new-flag-MexMobile {
    background-position: -399px -650.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MY {
    background-position: -449px -650.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MZ {
    background-position: -500px -649.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-NE {
    background-position: -50px -696.0000457763672px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-NG {
    background-position: -101px -700.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-NI {
    background-position: -150px -700.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-NL {
    background-position: -201px -700.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-NP {
    background-position: -301px -700.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-NR {
    background-position: -351px -699.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-NZ {
    background-position: -401px -700.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-OM {
    background-position: -451px -700.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-PE {
    background-position: -550px -700.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-PF {
    background-position: -1px -750.0000419616699px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-PG {
    background-position: -50px -750.0000419616699px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-PH {
    background-position: -101px -750.0000419616699px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-PK {
    background-position: -150px -750.0000419616699px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-PL {
    background-position: -200px -750.0000419616699px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-PR {
    background-position: -250px -750.0000419616699px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-PT {
    background-position: -350px -750.0000419616699px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-PY {
    background-position: -450px -751.0000419616699px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-QA {
    background-position: -500px -750.0000419616699px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-RE {
    background-position: -550px -749.0000419616699px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-RO {
    background-position: 0 -800.0000457763672px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-RS {
    background-position: -51px -801.0000457763672px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-RU {
    background-position: -100px -801.0000457763672px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-RW {
    background-position: -151px -799.0000457763672px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SA {
    background-position: -200px -800.0000457763672px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SB {
    background-position: -250px -799.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SC {
    background-position: -301px -799.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SD {
    background-position: -351px -800.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SG {
    background-position: -450px -800.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SI {
    background-position: -501px -800.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SK {
    background-position: -551px -800.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SL {
    background-position: -2px -851.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SM {
    background-position: -50px -850.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SN {
    background-position: -101px -851.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SO {
    background-position: -150px -849.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SR {
    background-position: -201px -850.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-ST {
    background-position: -250px -851.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SV {
    background-position: -300px -850.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SZ {
    background-position: -400px -850.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TC {
    background-position: -449px -850.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TG {
    background-position: -549px -849.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TH {
    background-position: -1px -895.0000610351562px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TJ {
    background-position: -51px -895.0000610351562px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TL {
    background-position: -100.00001525878906px -895.0000610351562px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TM {
    background-position: -150.00001525878906px -895.0000610351562px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TN {
    background-position: -199px -895.0000610351562px ;
}
.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TO {
    background-position: -249px -895.0000610351562px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TR {
    background-position: -299.0000305175781px -895.0000610351562px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TT {
    background-position: -349.0000305175781px -895.0000610351562px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TV {
    background-position: -399px -896.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TW {
    background-position: -448px -895.0000610351562px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-TZ {
    background-position: -497px -895.0000610351562px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-UA {
    background-position: -547px -895.0000610351562px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-US {
    background-position: -51px -945.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-UY {
    background-position: -100.00001525878906px -945.0000610351562px ;

}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-UZ {
    background-position: -150.00001525878906px -944.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-VA {
    background-position: -199px -944.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-VC {
    background-position: -249px -946.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-VE {
    background-position: -298.0000305175781px -945.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-VG {
    background-position: -348.0000305175781px -944.0000305175781px ;
}


.intnl-ph-no-new-flag.intnl-ph-no-new-flag-VN {
    background-position: -448px -945.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-VU {
    background-position: -498px -945.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-YE {
    background-position: -1px -996.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-ZA {
    background-position: -51.00000762939453px -996.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-ZM {
    background-position: -101.00001525878906px -995.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-ZW {
    background-position: -151.00001525878906px -996.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MD {
    background-position: -201px -601.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-LS {
    background-position: -450px -548.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MO {
    background-position: -1px -650.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-MQ {
    background-position: -50px -649.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-NA {
    background-position: -550px -650.0000152587891px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-NC {
    background-position: 0 -696.0000457763672px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-NO {
    background-position: -250px -700.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-PA {
    background-position: -501px -699.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SE {
    background-position: -401px -800.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-SY {
    background-position: -350px -849.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-UG {
    background-position: -2.000030517578125px -945.0000610351562px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-VI {
    background-position: -398px -945.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-WS {
    background-position: -547px -946.0000305175781px ;
}

.intnl-ph-no-new-flag.intnl-ph-no-new-flag-PW{
    background-position: -400px -750.0000419616699px ;
}

input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }