@import './common/Breakpoints.scss';

//global styles
:global(.di-input) {

  :global(.rbcb-clickspace-container) {
    display: inline-block;
    height: 42px;
    width: 42px;
    position:relative;
    flex: none;

    :global(.rbcb-lbl)::before {
      color: #666666;
      content: "\a0";
      display: inline-block;
      position: absolute;
    }
  }
}

//Styling that both the Checkbox and Radio Button components use
.component-label-container input[type=radio],
.component-label-container input[type=checkbox] {
  opacity: 0;
  position: absolute;
  z-index: 12;
  width: 100%;
  height: 100%;
}

.disabled {
  cursor: not-allowed;
}

.component-label-container {
  display: inline-flex;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 0;
}

.label-container {
  margin-left: 9px;
  margin-bottom: 0px;
}

.label-container.sm {
  margin-left: 4px
}

@media (max-width: $xs-max) {
  .label-container {
    margin-left: 4px;
  }

  .label-container.sm {
    margin-left: -1px
  }
}

.component-label-container input[type=radio]:focus+.radio-lbl::before,
.component-label-container input[type=checkbox]:focus+.checkbox-lbl::before {
  //eventually we will remove the "::before" when full original markup radio buttons and checkboxes are used so the label and buttons are outlined
  outline: thin dotted;
}

//Styling for Checkbox
.checkbox-lbl::before {
  height: 30px;
  width: 30px;
  border: solid #666666 2px;
  left: 6px;
  top: 6px;
}

.checkbox-lbl.sm::before{
  height: 20px;
  width: 20px;
  left: 11px;
  top: 11px;
}

.component-label-container input[type=checkbox]:checked+.checkbox-lbl::before{
  font-family: leapfrog;
  display: inline-block;
  content: '\e61d';
  font-size: 16px;
  padding-top: 3px;
  padding-left: 5px;
  color: #666666;
}

.component-label-container input[type=checkbox]:checked+.checkbox-lbl.sm::before{
  font-size: 11px;
  padding-top: 2px;
  padding-left: 2px;
}

.checkbox-lbl.disabled::before {
  opacity: 0.4;
}

//Styling for Radio Button
.radio-lbl::before {
  height: 26px;
  width: 26px;
  border-radius: 18px;
  box-shadow: 0 0 0 2px #666666;
  left: 8px;
  top: 8px;
}

.component-label-container input[type=radio]:checked+.radio-lbl::before {
  background-color: #666666;
  border: solid 4px #ffffff;
}

.radio-lbl.sm::before {
  height: 16px;
  width: 16px;
  left: 13px;
  top: 13px;
}

.component-label-container input[type=radio]:checked+.radio-lbl.sm::before {
  border-width: 2px;
}

.radio-lbl.disabled::before {
  opacity: 0.4;
}
:global(.di-radio .rbcb-clickspace-container) input[type=radio]:hover,
:global(.di-radio) .label-container:hover {
  cursor: pointer;
}

:global(.di-radio) .label-container > * {
  display: block;
  pointer-events: none;
}