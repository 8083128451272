@font-face {
	font-family: 'leapfrog';
	src:url('fonts/leapfrog.eot?-peo0lh');
	src:url('fonts/leapfrog.eot?#iefix-peo0lh') format('embedded-opentype'),
		url('fonts/leapfrog.ttf?-peo0lh') format('truetype'),
		url('fonts/leapfrog.woff?-peo0lh') format('woff'),
		url('fonts/leapfrog.svg?-peo0lh#leapfrog') format('svg');
	font-weight: normal;
	font-style: normal;
}

.di-icon, [class*='di-icon-'] {
	font-family: 'leapfrog';
	display: inline-block;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.di-icon-accounts:before {  content: "\e600";  }
.di-icon-add:before {  content: "\e601";  }
.di-icon-back:before {  content: "\e602";  }
.di-icon-billpay:before {  content: "\e603";  }
.di-icon-calendar:before {  content: "\e604";  }
.di-icon-call:before {  content: "\e605";  }
/*.di-icon-checkmark-for-checkbox:before {  content: "\e606";  }*/
.di-icon-close:before {  content: "\e607";  }
.di-icon-close-hide:before {  content: "\e608";  }
.di-icon-computer:before {  content: "\e609";  }
.di-icon-delete:before {  content: "\e60a";  }
.di-icon-email:before {  content: "\e60c";  }
.di-icon-error:before {  content: "\e60d";  }
.di-icon-forward:before {  content: "\e60e";  }
.di-icon-help:before {  content: "\e60f";  }
.di-icon-help-outline:before {  content: "\e610";  }
.di-icon-info:before {  content: "\e611";  }
.di-icon-notifications:before {  content: "\e612";  }
.di-icon-open-show:before {  content: "\e613";  }
.di-icon-paypeople:before {  content: "\e614";  }
.di-icon-rdc:before {  content: "\e615";  }
.di-icon-refresh:before {  content: "\e616";  }
.di-icon-rewards:before {  content: "\e617";  }
.di-icon-scheduled:before {  content: "\e618";  }
.di-icon-search:before {  content: "\e619";  }
.di-icon-security:before {  content: "\e61a";  }
.di-icon-settings:before {  content: "\e61b";  }
.di-icon-sort:before {  content: "\e61c";  }
.di-icon-success:before {  content: "\e61d";  }
.di-icon-text-mobile-alerts:before {  content: "\e61e";  }
.di-icon-transfers:before {  content: "\e620";  }
.di-icon-warning:before {
	content: "\e60b";
	color: rgb(254, 200, 42);
}
.di-icon-warning:after {
	content: "!";
	color: rgb(51, 51, 51);
	display: inline-block;
	margin-left: -0.78em;
	margin-top: 0.14em;
	font-size: .8em;
	position: absolute;
    font-weight: bolder;
}

.di-icon-danger:before {
	content: "\e60b";
	color: #c90202;
}
.di-icon-danger:after {
	content: "!";
	color: #FFFFFF;
	display: inline-block;
	margin-left: -0.78em;
	margin-top: 0.14em;
	font-size: .8em;
	position: absolute;
	font-weight: bolder;
}

.di-icon-warning-outline:before {  content: "\e622";  }


/** glyphicon-Overrides */

.glyphicon-remove, .glyphicon-plus, .glyphicon-envelope,
.glyphicon-chevron-left, .glyphicon-chevron-right,
.glyphicon-ok, .glyphicon-bell, .glyphicon-question-sign,
.glyphicon-remove-sign, .glyphicon-calendar, .glyphicon-warning-sign,
.glyphicon-earphone, .glyphicon-remove, .glyphicon-alert,
.glyphicon-info-sign, .glyphicon-save, .glyphicon-star,
.glyphicon-time, .glyphicon-refresh, .glyphicon-triangle-bottom,
.glyphicon-transfer
{
	font-family: 'leapfrog';
	display: inline-block;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.glyphicon-remove:before {  content: "\e607";  }
.glyphicon-plus:before {  content: "\e601";  }
.glyphicon-envelope:before {  content: "\e60c";  }
.glyphicon-chevron-left:before {  content: "\e602";  }
.glyphicon-chevron-right:before {  content: "\e60e";  }
.glyphicon-ok:before {  content: "\e61d";  }
.glyphicon-bell:before {  content: "\e612";  }
.glyphicon-question-sign:before {  content: "\e60f";  }
.glyphicon-remove-sign:before {  content: "\e60a";  }
.glyphicon-calendar:before {  content: "\e604";  }
.glyphicon-warning-sign:before {  content: "\e622";  }
.glyphicon-earphone:before {  content: "\e605";  }
.glyphicon-remove:before {  content: "\e607";  }
.glyphicon-alert:before {  content: "\e60d";  }
.glyphicon-info-sign:before {  content: "\e611";  }
.glyphicon-save:before {  content: "\e613";  }
.glyphicon-star:before {  content: "\e617";  }
.glyphicon-refresh:before {  content: "\e616";  }
.glyphicon-time:before {  content: "\e618";  }
.glyphicon-triangle-bottom:before {  content: "\e61c";  }
.glyphicon-transfer:before {  content: "\e620";  }

