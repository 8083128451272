/* start of calendar styles */
.rw-calendar .rw-header .rw-btn-view {
    background-color: initial;
}
.rw-calendar .rw-header .rw-btn {
    color: #05c;
}
.di-rw-btn {
    line-height: 2.286em;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid transparent;
    padding: 0;
    white-space: nowrap;
}
.di-media-body {
    display: table-cell;
    vertical-align: top;
}
.rw-calendar thead {
    background-color: transparent;
}
.rw-calendar thead > tr {
    border-bottom: none;
}
.rw-calendar-grid .rw-btn {
    text-align: center;
}
.rw-calendar-grid th {
    text-align: center;
    font-weight: normal;
    padding: 0;
}
.rw-calendar-grid .rw-state-focus {
    border: none;
}
.rw-calendar-grid th:first-child .rw-nav-view, .rw-calendar-grid th:last-child .rw-nav-view {
    color: inherit;
}
.rw-calendar-grid td {
    text-align: center;
}
.rw-calendar-grid td .rw-btn {
    width: 35px;
    height: 35px;
    padding: 0;
    border-radius: 50%;
    background-color: initial;
}
.rw-calendar-grid td .rw-btn:hover {
    background: #7abe43;
    color: white;
    border: solid 1px #7abe43;
}
.rw-calendar-grid td .rw-now {
    border: solid 1px #05c;
}
.rw-calendar-grid td .rw-state-selected {
    border: solid 1px #7abe43;
    background: #7abe43;
    color: white;
}
.rw-calendar-grid td span {
    color: #222;
}
.rw-calendar-grid.rw-nav-view .rw-btn {
    width: auto;
    height: auto;
    border-radius: 4px;
}
.rw-widget {
    border: none;
}
.rw-widget.rw-state-focus, .rw-widget.rw-state-focus:hover {
    box-shadow: none;
    border: none;
}
.rw-off-range {
    display: none;
}
.rw-i {
    font-family: leapfrog;
}
.rw-i-caret-left:before {
    content: '\e602';
}
.rw-i-caret-right:before {
    content: '\e60e';
}
.di-calendar {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: inherit;
    padding-right: inherit;
}
.di-calendar .media {
    padding: 0 14px 0 14px;
    margin-top: 30px;
}
.di-calendar .media .media-body {
    color: #05c;
    cursor: pointer;
}
.di-calendar-disable-weekends {
    color: #666;
    cursor: not-allowed;
    background: #f2f2f2;
}
.di-calendar-remove-hover-styles {
    background-color: white !important;
    border: white !important;
}
.di-calendar-disable-selection:hover {
    background: inherit !important;
    color: inherit !important;
    border-color: transparent !important;
    cursor: initial;
}
.di-calendar .d7, .di-calendar .d6 span, .di-calendar .d7 span, .di-calendar .d6 {
    background-color: #f2f2f2 !important;
}
.di-calendar-disable-selection.rw-now {
    border-color: #05c !important;
}
/* end of calendar styles*/
/* start of date picker styles */
.di-datepicker .form-group {
    max-width: 400px;
}
.di-datepicker .form-group .form-control-feedback {
    top: 12px;
}
.di-datepicker .form-group .form-control-feedback .di-svg {
    top: -20px;
}
.di-datepicker .form-group .form-control:focus {
    border-left: solid 2px #666;
}
.di-datepicker .input-group-addon {
    padding-right: 35px;
}
.di-datepicker .form-group {
    margin-bottom: 0;
}
.di-datepicker .di-datepicker-error {
    border: solid 1px #c90202;
}
.di-datepicker .di-datepicker-error label {
    background: #efd7d7;
    color: #c90202;
    border: none;
}
.di-datepicker .di-datepicker-error .form-control {
    border: none;
}
.di-datepicker .di-datepicker-error .form-control:focus {
    border: none;
}
.di-calendar-wrapper {
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.45);
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 14px;
    padding-right: 14px;
    max-width: 300px;
}
.di-calendar-wrapper .di-calendar {
    box-shadow: none;
    padding-bottom: 20px;
}
.di-datepicker-close-btn {
    border: none;
    padding: 0 15px 0 15px;
}
.di-datepicker-close-btn:hover, .di-datepicker-close-btn:focus {
    background: none;
}
.di-calendar-wrapper-popover {
    position: absolute;
    z-index: 10;
}
.di-calendar-space {
    height: 400px;
}
.di-from-to-calendar-wrapper {
    position: absolute;
    z-index: 1;
}
.di-calendar-from-date-label {
    padding-right: 5px;
}
.di-calendar-to-date-label {
    padding-left: 5px;
    padding-right: 5px;
}
.di-calendar-go-button {
    margin-left: 5px;
}
.di-calendar-select-send-date-end {
    background-color: #ccc;
    border-color: #ccc;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: #d44403;
    border-color: #d44403;
    color: white;
}
.di-calendar-select-deliver-by-date-start {
    background-color: #ccc;
    border-color: #ccc;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: #7abe43;
    border-color: #7abe43;
    color: white;
}
.di-calendar-from-to-input-wrapper {
    position: relative;
    display: inline-block;
}
.di-calendar-xs {
    max-width: none;
}
.di-calendar-xs .di-calendar {
    max-width: none;
}
/* end of date picker styles */
