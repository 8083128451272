/* almanac-picker Date Picker*/


:global(.di-almanac-picker) {
    .input-label {
        margin-bottom: 8px;
    }

    .date-input {
        background-color: #fff;
    }

    :global(.calendar:not(.di-svg)) {
        display: none;
        max-width: 300px;
        background-color: #fff;
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.45);

        :global(.di-almanac) {
            padding-bottom: 20px;
            box-shadow: none;
            z-index: 1;
        }

        :global(.top) {
            padding: 20px 15px 5px 15px;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;

            :global(.section) {
                -webkit-flex-grow: 1;
                flex-grow: 1;
            }
            :global(.close) {
                -webkit-flex: none;
                flex: none;
            }
        }
        :global(.bottom) {
            padding-bottom: 20px;
        }
    }

    :global(.calendar.open) {
        display: block;
        margin-left: 2px;
    }

    :global(.form-group) {
        margin: 0;
    }

    :global(.form-control-feedback) {
        z-index: 3;
    }

    :global(.form-control-feedback .di-svg) {
        top: -8px;
    }

    :global :local(.has-label).form-control-feedback .di-svg {
        top: -4px;
    }

    :local(.manual-entry-enabled-calendar-icon-container) {
        pointer-events: auto;
    }

    :global(.almanac-overlay) {
        position: absolute;
        z-index: 100;
    }
}

:global(.help-block).error-container {
    display: flex;
    align-items: center;
    word-wrap: break-word;
    span:global(.di-svg) {
        flex: none;
    }
    .error-message-container {
        margin-left: 7px;
        background: #FFF;
    }
}

