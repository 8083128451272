@import './common/Breakpoints';

:global(.bp-date-picker) {
  :global(.form-control) {
    box-shadow: none;
  }

  :global(.form-control:focus) {
    border: 2px solid #666;
  }

  :global(.form-group .form-control-feedback) {
    right: 10px;
  }

  :global(.di-almanac-picker) {
    :global(.form-group) {
      margin-bottom: 15px;
      max-width: 400px;
    }

    :global(.calendar:not(.di-svg)) {
      padding: 0 10px 10px 10px;
      max-width: 320px;
    }
  }

  :global(.input-group-addon) {
    min-width: 80px;
    text-align: left;
  }

  :global(.text-danger) {
    margin-top: 5px;
  }

}

// Temporary bill pay fixes
// TODO remove once bill pay removes extra padding
@media (max-width: $xs-max) {
  :global(#Billpay) {
    overflow-x: hidden;

    :global(input#amount) {
      padding: 0;
    }

    :global(#payToSelectedItem),
    :global(#payFromSelectedItem) {
      span {
        padding: 0;
      }
    }
  }
}

//TODO fix calendar globally
@media (min-width: $sm-min) {
  :global(#paymentForm) {
    :global(.di-almanac) {
      min-width: inherit;
    }

    :global(.di-almanac .day .no) {
      min-width: inherit;
      width: 100%;
      height: 100%;
      max-width: 38px;
      max-height: 38px;
    }
  }
}

