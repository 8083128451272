.di-svg-icon {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-block;
    background-color: #333;
}

.di-svg {
    width: 1em;
    height: 1em;
    position: relative;
    top: -2px;
    margin: 0;
    display: inline-block;
    font-size: 1em;
    vertical-align: middle;
    fill: currentColor;
}

.di-svg.sm {
    width: 16px;
    height: 16px;
}

.di-svg.lg {
    width: 32px;
    height: 32px;
}

/* Close icon always takes this specific color and is not affected by branding */
.di-svg.close {
    color: #666666;
    opacity: 1;
}

.di-svg.close:hover,
.di-svg.close:active {
    color: #333333;
    opacity: 1;
}
/* End of close icon styles */

/* ======= MONO-COLORED SVG ICON ============== 

.di-svg-icon.accounts{
    mask: url('../img/svg/current-color/accounts.svg');
    -webkit-mask-image: url('../img/svg/current-color/accounts.svg');
}



.di-svg-icon.add{
    mask: url('../img/svg/current-color/add.svg');
    -webkit-mask-image: url('../img/svg/current-color/add.svg');
}

.di-svg-icon.alert-fill{
    mask: url('../img/svg/current-color/alert-fill.svg');
    -webkit-mask-image: url('../img/svg/current-color/alert-fill.svg');
}

.di-svg-icon.alert-outline{
     mask: url('../img/svg/current-color/alert-outline.svg');
     -webkit-mask-image: url('../img/svg/current-color/alert-outline.svg');
 }

.di-svg-icon.back{
    mask: url('../img/svg/current-color/back.svg');
    -webkit-mask-image: url('../img/svg/current-color/back.svg');
}

.di-svg-icon.bill-pay{
    mask: url('../img/svg/current-color/bill-pay.svg');
    -webkit-mask-image: url('../img/svg/current-color/bill-pay.svg');
}

.di-svg-icon.calendar{
    mask: url('../img/svg/current-color/calendar.svg');
    -webkit-mask-image: url('../img/svg/current-color/calendar.svg');
}

.di-svg-icon.call{
    mask: url('../img/svg/current-color/call.svg');
    -webkit-mask-image: url('../img/svg/current-color/call.svg');
}

.di-svg-icon.check-image{
    mask: url('../img/svg/current-color/check-image.svg');
    -webkit-mask-image: url('../img/svg/current-color/check-image.svg');
}

.di-svg-icon.checkmark{
    mask: url('../img/svg/current-color/checkmark.svg');
    -webkit-mask-image: url('../img/svg/current-color/checkmark.svg');
}

.di-svg-icon.close-sign{
    mask: url('../img/svg/current-color/close.svg');
    -webkit-mask-image: url('../img/svg/current-color/close.svg');
}

.di-svg-icon.computer{
    mask: url('../img/svg/current-color/computer.svg');
    -webkit-mask-image: url('../img/svg/current-color/computer.svg');
}

.di-svg-icon.delete{
    mask: url('../img/svg/current-color/delete.svg');
    -webkit-mask-image: url('../img/svg/current-color/delete.svg');
}

.di-svg-icon.edit{
    mask: url('../img/svg/current-color/edit.svg');
    -webkit-mask-image: url('../img/svg/current-color/edit.svg');
}

.di-svg-icon.email{
    mask: url('../img/svg/current-color/email.svg');
    -webkit-mask-image: url('../img/svg/current-color/email.svg');
}
.di-svg-icon.forward{
    mask: url('../img/svg/current-color/forward.svg');
    -webkit-mask-image: url('../img/svg/current-color/forward.svg');
}
.di-svg-icon.help{
    mask: url('../img/svg/current-color/help.svg');
    -webkit-mask-image: url('../img/svg/current-color/help.svg');
}
.di-svg-icon.help-outline{
    mask: url('../img/svg/current-color/help-outline.svg');
    -webkit-mask-image: url('../img/svg/current-color/help-outline.svg');
}
.di-svg-icon.hide-sign{
    mask: url('../img/svg/current-color/hide.svg');
    -webkit-mask-image: url('../img/svg/current-color/hide.svg');
}
.di-svg-icon.hide-sign-1{
    mask: url('../img/svg/icons.svg#hide');
    -webkit-mask-image: url('../img/svg/icons.svg#hide');
}
.di-svg-icon.info{
    mask: url('../img/svg/current-color/info.svg');
    -webkit-mask-image: url('../img/svg/current-color/info.svg');
}
.di-svg-icon.notifications{
    mask: url('../img/svg/current-color/notifications.svg');
    -webkit-mask-image: url('../img/svg/current-color/notifications.svg');
}
.di-svg-icon.pay-people{
    mask: url('../img/svg/current-color/pay-people.svg');
    -webkit-mask-image: url('../img/svg/current-color/pay-people.svg');
}
.di-svg-icon.print{
    mask: url('../img/svg/current-color/print.svg');
    -webkit-mask-image: url('../img/svg/current-color/print.svg');
}
.di-svg-icon.recycle{
    mask: url('../img/svg/current-color/recycle.svg');
    -webkit-mask-image: url('../img/svg/current-color/recycle.svg');
}
.di-svg-icon.refresh{
    mask: url('../img/svg/current-color/refresh.svg');
    -webkit-mask-image: url('../img/svg/current-color/refresh.svg');
}

.di-svg-icon.repeat{
    mask: url('../img/svg/current-color/repeat.svg');
    -webkit-mask-image: url('../img/svg/current-color/repeat.svg');
}
.di-svg-icon.refresh{
    mask: url('../img/svg/current-color/refresh.svg');
    -webkit-mask-image: url('../img/svg/current-color/refresh.svg');
}
.di-svg-icon.rewards{
    mask: url('../img/svg/current-color/rewards.svg');
    -webkit-mask-image: url('../img/svg/current-color/rewards.svg');
}
.di-svg-icon.search{
    mask: url('../img/svg/current-color/search.svg');
    -webkit-mask-image: url('../img/svg/current-color/search.svg');
}

.di-svg-icon.security{
    mask: url('../img/svg/current-color/security.svg');
    -webkit-mask-image: url('../img/svg/current-color/security.svg');
}

.di-svg-icon.settings{
    mask: url('../img/svg/current-color/settings.svg');
    -webkit-mask-image: url('../img/svg/current-color/settings.svg');
}

.di-svg-icon.show-sign{
    mask-image: url('../img/svg/current-color/show.svg');
    -webkit-mask-image: url('../img/svg/current-color/show.svg');
}

.di-svg-icon.sort{
    mask: url('../img/svg/current-color/sort.svg');
    -webkit-mask-image: url('../img/svg/current-color/sort.svg');
}

.di-svg-icon.text-mobile-alerts{
    mask: url('../img/svg/current-color/text-mobile-alerts.svg');
    -webkit-mask-image: url('../img/svg/current-color/text-mobile-alerts.svg');
}
.di-svg-icon.text-mobile-alerts-outline{
    mask: url('../img/svg/current-color/text-mobile-alerts-outline.svg');
    -webkit-mask-image: url('../img/svg/current-color/text-mobile-alerts-outline.svg');
}
.di-svg-icon.transfer-money{
    mask: url('../img/svg/current-color/transfer-money.svg');
    -webkit-mask-image: url('../img/svg/current-color/transfer-money.svg');
}
*/
/* ======= MULTI-COLORED SVG ICON ============== 

.di-svg-icon.alert-error-sign{
    mask-image: url('../img/svg/fixed-color/alert-error.svg');
    -webkit-mask-image: url('../img/svg/fixed-color/alert-error.svg');
}


.di-svg-icon.alert-warning-sign{
    mask-image: url('../img/svg/fixed-color/alert-warning.svg');
    -webkit-mask-image: url('../img/svg/fixed-color/alert-warning.svg');
}

.di-svg-icon.scheduled{
    mask-image: url('../img/svg/fixed-color/scheduled.svg');
    -webkit-mask-image: url('../img/svg/fixed-color/scheduled.svg');
}

.di-svg-icon.success {

    mask-image: url('../img/svg/fixed-color/success.svg');
    -webkit-mask-image: url('../img/svg/fixed-color/success.svg');
}
*/
