@import './common/Breakpoints';

//InputField
:global(input[type=number].di-input::-webkit-inner-spin-button),
:global(input[type=number].di-input::-webkit-outer-spin-button) {
    -webkit-appearance: none;
    margin: 0;
}

// Removes the IE specific icons that appear inside inputs
.input-field-group-addon {
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}

:global(input[type=number].di-input) {
    -moz-appearance:textfield;
}

.input-text-left-padding {
  padding-left: 35px;
}

.input-addon-icon-left-position {
  left :0;
}

.input-addon-icon-top {
  top: -7px;
}

//InputField Group Addon
.di-input-inset {
  position: relative;
}

.di-input-inset-addon-label-on-same-line {
    box-shadow: none;
}

.di-input-inset-addon-left {
  bottom: 0;
  margin-left: 0.9375rem;
  left: 0;
  right: auto;
  position: absolute;
  top: 11px;
  z-index: 20;
}

.show-hide-links {
  position: absolute;
  right: 0;
  margin-right: 2px;
  margin-top: -39px;
  display: block;
  padding: 11px 15px;
  z-index: 20;
  font-size: x-small;
  text-decoration: none;
}

.di-input-inset-addon-top-label-top{
  top: 35px;
}

.input-group-text-left-padding {
  padding-left: 30px;
}

.error-message {
  padding-left: 5px;
}

.input-field-group-addon {
  position: relative;

  :global .has-error .input-label {
    background-color: transparent;
  }

  :global .has-error .help-block {
    color: #c90202;
    fill: #c90202;
    font-size: 14px;
    margin: 7px 0px 10px 0px;
  }

  :global(.input-label) {
    min-width: 80px;
    border: 0px;
    padding: 0px;
    text-align: left;
    -webkit-align-self: center;
    align-self: center;
  }

  :global .input-group  {
    width: 100%;
  }
}

@media (max-width: $xs-max) {

  .input-group-addon {
      font-size: 18px;
      background-color: transparent;
      padding: 0px;
      text-align: left;
      margin: 0px;
  }

  .show-hide-links-change-height-top {
    margin-top: -40px;
    border: 0px;
  }

  .di-input-inset {
    -webkit-align-self: center;
    align-self: center;
  }

  .display-mirror {
    .mirror-span {
      position: relative;
      float: right;
      color: transparent;
      background-color: transparent;
      font-size: 16px;
      font-weight: bold;
      max-width: calc(100% - 40px);
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
    }

    .mirror-inset {
      position: relative;
      float: right;
      top: 0;
      font-size: 16px;
      font-weight: bold;
      margin:0px 10px 0px 0px;
      padding: 0px;
      z-index: 0;
    }

    .mirror-textbox {
      position: absolute;
      right: 0px;
      height: 100%;
      top: 0;
      color: #333;
      font-size: 16px;
      font-weight: bold;
      background: transparent;
      text-align: right;
      min-width: 100px;
      max-width: calc(100% - 40px);
      padding: 0px;
    }

    .di-input-inset {
      -webkit-flex-grow: 1;
      flex-grow: 1;
      overflow: hidden;
    }

    :global .has-error .form-control {
      border: none;
    }

    :global .has-error .help-block {
      border-top: 1px solid #c90202;
      padding: 10px 20px;
    }
  }

  .input-field-group-addon {
    :global .has-error .form-control:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    :global .has-error .form-control {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    :global .input-group  {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      min-height: 72px;
      padding: 0px 20px;
    }
  }

  .input-field-money-form-group {
    input {
      text-align: right;
      padding-right: 0px;
      border:0px;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    :global .form-control:focus {
      border: 0px;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.input-field-money-form-group {
  margin-bottom: 0;
}

@media (min-width: $sm-min) {
  .input-field-money-form-group {
    max-width: 400px;
  }

  .show-hide-links-change-height-top {
    margin-top: -27px;
  }
}
