.input-text-area {
   width: 100%;
}

.input-text-area .input-text {
   width: 100%;
   border: 0px;
   border-bottom: 3px solid #CCC;
   padding: 15px 25px 10px 25px;
   text-align: right;
   outline: none;
   color: #0055CC;
   font-size: 22px;
}

.input-text-area .input-text:focus {
  border-color: #0055CC;
}

.key-pad-area {
  padding: 20px 30px 40px;
}

.key-row {
  display: flex;
  justify-content: space-between;
}

.key-col {
  width: 42px;
  height: 42px;
}

.key-btn {
  background-color: #fff;
  border: 0px;
  width: 100%;
}

.key-btn:-moz-focusring {
  outline: 1px dotted;
}

.key-btn:focus {
  position: relative;
  z-index: 1;
}

.key-btn:hover {
  background-color: #ededed;
}

