/* Split Button Dropdown */
.split-button-dropdown {
    background-color: #ededed;
    height: 35px;
    width: 45px;
    border: 1px solid #acacac;
    border-radius: 0 2px 2px 0;
    margin-left: -1px;
    font-size: 16px;
    border: 1px solid #acacac;
    background-image: -webkit-linear-gradient(top, #ffffff 0%, #dddddd 100%);
    background-image: -o-linear-gradient(top, #ffffff 0%, #dddddd 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#dddddd));
    background-image: linear-gradient(to bottom, #ffffff 0%, #dddddd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dddddd', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.45);
    padding-bottom: 2.5px; /* Needed for proper dropdown button alignment in Firefox */
}
.split-button-dropdown:hover {
    background-image: -webkit-linear-gradient(top, #CCCCCC 0%, #CCCCCC 100%);
    background-image: -o-linear-gradient(top, #CCCCCC 0%, #CCCCCC 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#CCCCCC), to(#CCCCCC));
    background-image: linear-gradient(to bottom, #CCCCCC 0%, #CCCCCC 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CCCCCC', endColorstr='#CCCCCC', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
}
.split-button-dropdown-tertiary {
    @extend .split-button-dropdown;
    background-color: #F2F2F2;
    background-image: none;
    box-shadow: none;
}
.split-button-dropdown-tertiary:hover {
    @extend .split-button-dropdown:hover;
}
.split-button-dropdown :global(.di-svg), .split-button-dropdown-tertiary :global(.di-svg) {
    color: inherit;
}

.split-button-extended {
    width: 265px;
    min-width: 0;
}
:global(.main-button) {
    margin-top: 4px;
}
