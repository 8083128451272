@import '../common/Breakpoints';

.modal-container {
  display: -webkit-flex !important;
  display:         flex !important;
  -webkit-align-items: center;
  align-items: center;
  width: auto !important;
}

.loader-modal {
  width: 226px;
}

@media (max-width: $xs-max) {
  .loader-modal {
    margin: auto;
  }
}

.loader-modal > * {
  padding: 20px;
  border-radius: 5px;
  background-color: #EDEDED;
}

.loader-text {
  margin-top: 20px;
}

.loader-modal-body {
  margin: 0;
  display: -webkit-flex;
  display:         flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}

.contained-modal-container {
  position: relative;
}
