@import './common/Breakpoints';

.di-widget-header {
  display:flex;
  align-items: center;
}

.di-widget-header:focus {
  outline: thin dotted;
}
:global(.di-primary-header) {
  background-color: #666666;
  color: #ffffff;
}

:global(.di-secondary-header) {
  background-color: #ffffff;
  color: #333333;
}

.di-widget-header-title {
  font-size: 28px;
  font-family: Arial, sans-serif;
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  padding: 0;
}

a.di-widget-header-link span:global(.di-svg){
  margin: 0 8px;
}

.di-widget-header-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  text-decoration: none;
}

.di-widget-header-link-title {
  margin: 0;
}

.di-widget-header-link:last-child {
  margin-right: 0;
}

@media (max-width: $sm-max) {
  .di-widget-header {
    padding: 20px;
  }
}

@media (min-width: $md-min) {
  .di-widget-header{
    padding: 30px;
  }
}

:global(.di-primary-header), :global(.di-secondary-header).no-underline{
  padding-bottom: 15px;
}

.di-widget-header:global(.di-secondary-header):not(.no-underline) {
  padding-bottom: 12px;
  border-bottom: 3px solid #CCCCCC;
  margin-bottom: 0
}


