.di-modal-container {
  :global(.modal) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  :global(.modal-dialog) {
    position: fixed;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  :global(.modal-content) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
    overflow: auto;
    padding-left: 0;
    padding-right: 0;
  }

  :global(.modal-header) {
    padding-right: 15px;
  }

  :global(.modal-header>.close) {
    margin-right: 0;
  }
}