body {
  background-color: #EDEDED;
}
hr {
	margin: 10px 0px;
    border-top: 1px solid #cccccc;
}
h1, .h1, h2, .h2, h3, .h3 {
	margin: 0px;
	padding: 10px 0px 10px 0px;
}

/* This is to override the default bootstrap behavior that stops the header from having an outline when focused*/
h1:focus {
    outline: auto !important;
}

.radio label, .checkbox label {
	padding-left: 0px;
}
/* Typography Style */

.text-success {
	color: #2E6F03;
}
.text-danger {
	color: #C90202;
}
a {
	color: #0055CC;
	text-decoration: underline;
}

a:focus {
    outline: thin dotted;
}

.navbar a {
	text-decoration: none;
}

/* Model Dialog Overrides */
.modal-content {
    padding: 30px 30px 40px 30px;
}
.modal-header {
    padding:0px;
    min-height:10px;
}
.modal-header > .close {
    margin-top: -18px;
    margin-right: -17px;
	height: 22px;
	font-size: 35px;
	line-height: 5px;
    color: #666666;
    opacity: 1;
}
.modal-header > .close:hover,
.modal-header > .close:active {
    color: #333333;
    opacity: 1;
}
.modal-header > .close:focus {
    outline: thin dotted;
}
.modal-title {
    padding: 0px 0px 0px 0px;
}
.modal-body {
    margin: 20px 0px 0px 0px;
	padding: 0px;
}
/*@deprecated will be removed in 1.3*/
/*.modal-body-content {*/
	/*padding: 20px;*/
	/*background-color: #EDEDED;*/
/*}*/

/*.modal-body-content hr,*/
.di-callout hr{
	border-top: 2px solid #cccccc;
}
.modal-body P {
	font-size: 14px;
	color: #666666;
}
.modal-footer {
    padding: 30px 0px 0px 0px;
    text-align: left;
}

/* PAGE HEADER */
.page-header {
	margin: 0 0 15px 0;
	padding: 0;
	border-bottom: 3px solid #ccc;
}

h1:focus, h2:focus{
     outline:none !important;
}
/* Apply class name on H1 */
.page-header > h1{
    padding: 0 0 5px 0;
    color: #666666;
}
.page-header .added-info{
    padding-bottom: 15px;
    font-size: 18px;
}

/* ========================================================== */
/* P O P O V E R   /   T O O L T I P                          */
/* ========================================================== */
.popover {
	background-color: #EDEDED;
	max-width: 320px;
	padding:20px 20px 20px 20px;
}
.popover .popover-content{
	background-color: #EDEDED;
	color:#333333;
	font-size: 14px;
	font-family: inherit;
	/*margin:20px;*/
	padding:0px;
}
.popover-title {
    padding: 0 0 10px;
    border: 0;
    background-color: transparent;
}
.popover-white + .popover, .popover-white + .popover .popover-content{
	background-color: #FFF;
}
.popover>.arrow, .popover>.arrow:after {
	border-width: 20px;
}

.popover.bottom>.arrow {
	top: -20px;
	margin-left: -20px;
}
.popover.top>.arrow {
	bottom: -20px;
	margin-left: -20px;
}
.popover.left>.arrow {
	right: -20px;
	margin-top: -20px;
}

.popover.right>.arrow {
	left: -20px;
	margin-top: -20px;
}
.popover.top>.arrow:after {
	border-top-color: #ededed;
	margin-left: -20px;
}
.popover-white + .popover.top>.arrow:after {
	border-top-color: #FFF;
}
.popover.bottom>.arrow:after {
	border-bottom-color: #ededed;
	margin-left: -20px;
}

.popover-white + .popover.bottom>.arrow:after {
	border-bottom-color: #ffffff;
}

.popover.left>.arrow:after {
	border-left-color: #ededed;
	bottom: -20px;
}
.popover-white + .popover.left>.arrow:after {
	border-left-color: #ffffff;
}
.popover.right>.arrow:after {
	border-right-color: #ededed;
	bottom: -20px;
}
.popover-white + .popover.right>.arrow:after {
	border-right-color: #ffffff;
}
.popover-close {
	margin-top:-9px !important;
	margin-right:-10px;
	font-size: 22px;
	line-height: 10px;
}

/** TABLE Override*/
.table {
    border-top: 1px solid #cccccc;
}

.table > thead > tr > th ,
.table > tbody > tr > td {

    font-size: 14px;
    font-weight: normal;
    border-bottom: 0px;
    color: #666666;
    padding: 13px 15px;
    background-image: url(../img/table-border.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 1px 35px;
	line-height: 15px;
}
.table > thead > tr th:last-child,
.table > tbody > tr > td {
    background-image:none;
}

.table > tbody > tr > td {
    font-size: 16px;
	line-height: 17px;
}
.table > tbody > tr > td{
	padding: 17px 15px;
}
.table > tbody > tr > td:first-child {
	padding: 17px 5px;
}

.table > thead > tr > th:first-child{
    padding: 13px 5px;
}

/** START - Override the BS-Theme alert behaviour */

.alert {
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.alert-success {
	filter: none;
	background: #e0ead9 none no-repeat;
	border-color: #e0ead9;
}
.alert-info {
    filter: none;
	background: #f5fbff none no-repeat;
    border-color: #f5fbff;
}
.alert-warning {
    filter: none;
	background: #fff8e5 none no-repeat;
    border-color: #fff8e5;
}
.alert-danger {
    filter: none;
	background: #ffe7e5 none no-repeat;
    border-color: #ffe7e5;
}

/** END - Override the BS-Theme alert behaviour */

.message {
	margin-top: 10px;
	margin-bottom:10px;
	padding-left:5px;
}
.message .media-body,
.alert .media-body{
	color:#333;
}

.message .media-left::before{
	font-family: "Glyphicons Halflings";
	content: "\e086";
}

.message-success .media-left::before,
.alert-success .media-left::before {
	color:#2E6F03;
	content: "\e61d";
}
.message-info .media-left::before,
.alert-info .media-left::before {
	font-family: "Glyphicons Halflings";
	color:#004B8D;
	content: "\e086";
}

.message-warning .media-left::before,
.alert-warning .media-left::before,
.message-danger .media-left::before,
.alert-danger .media-left::before {
    content: "\e60b";
    color: rgb(254, 200, 42);
}

.message-warning .media-left::after,
.alert-warning .media-left::after,
.message-danger .media-left::after,
.alert-danger .media-left::after {
    content: "!";
    color: rgb(51, 51, 51);
    display: inline-block;
    margin-left: -0.78em;
    margin-top: 0.18em;
    font-size: .8em;
    position: absolute;
    font-weight: bolder;
}

.message-danger .media-left::before,
.alert-danger .media-left::before {
    color: #C90202;
}
.message-danger .media-left::after,
.alert-danger .media-left::after{
    color: #FFFFFF;
}
.alert-message .media-left::before {
	font-family: "Glyphicons Halflings";
	content: "\e086";
}
.alert-message {
	background-color:#F5F5F5;
	border: 1px solid #E7E3E3;
}

.message-success .media-left::before,
.alert-success .media-left::before,
.message-warning .media-left::before,
.alert-warning .media-left::before,
.message-warning .media-left::after,
.alert-warning .media-left::after,
.message-danger .media-left::before,
.alert-danger .media-left::before,
.message-danger .media-left::after,
.alert-danger .media-left::after {

    font-family: 'leapfrog';
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* ========================================================== */
/* P A N E L                                                  */
/* ========================================================== */
.panel, .panel-heading {
    border-radius: 0;
}
.panel {
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.45);
}
.panel-default > .panel-heading {
    background: none;
    border-bottom-width: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0 15px;
    padding-right: 0;
    padding-left: 0;
}
.panel.panel-img {
    padding: 0;
}
@media (max-width: 767px){
    .panel {
        padding: 15px;
    }
    .panel-title, h3 {
        font-size: 16px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .panel {
        padding: 20px;
    }
}
@media (min-width: 992px) {
    .panel {
        padding: 30px;
    }
}


/* ========================================================== */
/* N A V B A R                                                */
/* ========================================================== */

/* ========================================== */
/* BADGE ICON STYLING REMOVED IN 1.2          */
/* ========================================== */
.navbar-glyphicon{
    font-size: 22px;
    left: -14px;
}
.navbar-menu-badge  {
    position: relative;
    top: -13px;
    z-index: 1;
    padding: 2px 3px;
    font-size: 11px;
    background-color: #000;
    border-radius: 0px;
}
.navbar-badge-btn {
    background-color: transparent;
    background-image: none;
    border: 0px solid transparent;
    border-radius: 0;
    color: whitesmoke;
    margin: 3px;
    padding: 3px;
}

/* ========================================================== */
/* I N P U T S                                             */
/* ========================================================== */
input[type='text'].sm {
    height: 35px;
}
.form-control {
    height: 42px;
    -webkit-box-shadow: inset 1px 1px 1px 0px rgba(0, 0, 0, 0.45);
    box-shadow: inset 1px 1px 1px 0px rgba(0, 0, 0, 0.45);
}
.form-control.sm {
    height: 35px;
	font-size: 14px;
}
.form-group {
    margin-bottom: 30px;
}
.form-group.sm input[type='text']{
    height: 35px;
}
.input-group.sm {
	font-size: 14px;
}
.input-group.sm .form-control{
	height: 35px;
}
.input-group.sm button{
	height: 35px;
	padding: 5px 10px;
}
.form-control:focus {
    border: 2px solid #666666;
    -webkit-box-shadow: none;
    box-shadow: none;
}
label {
    margin-bottom: 8px;
    font-weight: normal;
}
.help-block {
    color:#333333;
    font-size: 14px;
    margin-top: 7px;
}
.help-block .glyphicon, .help-block > img {
    margin-right: 7px;
}
.has-error .form-control {
    border: 2px solid #C90202;
}
.has-success .form-control {
    border-color: #2E6F03;
}
.has-warning .help-block > .glyphicon {
    color: #fec82a;
}

/* ========================================================== */
/* B U T T O N S                                              */
/* ========================================================== */

.btn,
button {
	height: 42px;
	font-size: 18px;
}

/*
 *  The line height needs to match the total height of the button, minus the border, so that the text will be vertically
 *  centered.
 */
a.btn {
	line-height: 40px;
	text-decoration: none;
}

a.btn.btn-tertiary,
a.btn.sm {
        line-height: 33px;
        text-decoration: none;
}

.btn + .btn,
button + button {
	margin-left:10px;
}

.btn.sm, .btn.btn-sm,
button .sm, button .btn-sm{
	height: 35px;
	font-size: 14px;
}

.btn-block+.btn-block {
	margin-left:0px;
}

.btn:focus {
    outline: thin dotted;
}

.btn-primary {
	border-radius: 2px;
	padding: 0px 20px;
	background-image: -webkit-linear-gradient(top, #767676 0%, #666666 100%);
	background-image: -o-linear-gradient(top, #767676 0%, #474747 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#767676), to(#666666));
	background-image: linear-gradient(to bottom, #767676 0%, #474747 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#767676', endColorstr='#666666', GradientType=0);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	background-repeat: repeat-x;
	border-color: #666666;
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.45);
}

.btn-secondary {
	color: #333333;
	padding: 0px 20px;
	border: 1px solid #acacac;
	border-radius: 2px;
	background-image: -webkit-linear-gradient(top, #ffffff 0%, #dddddd 100%);
	background-image: -o-linear-gradient(top, #ffffff 0%, #dddddd 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#dddddd));
	background-image: linear-gradient(to bottom, #ffffff 0%, #dddddd 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dddddd', GradientType=0);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	background-repeat: repeat-x;
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.45);

}
.btn-secondary:hover {
	background-image: -webkit-linear-gradient(top, #CCCCCC 0%, #CCCCCC 100%);
	background-image: -o-linear-gradient(top, #CCCCCC 0%, #CCCCCC 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#CCCCCC), to(#CCCCCC));
	background-image: linear-gradient(to bottom, #CCCCCC 0%, #CCCCCC 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CCCCCC', endColorstr='#CCCCCC', GradientType=0);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	background-repeat: repeat-x;
}

.btn-tertiary {
	height: 35px;
	padding: 0px 20px;
	font-size: 14px;
	color: #333333;
	border: 1px solid #ACACAC;
	border-radius: 2px;
	background-color: #FFFFFF;
}
.btn-tertiary:hover,
.btn-other:hover {
	background-color: #CCCCCC;
}

.btn.router {
    line-height: 32px;
    text-decoration: none;
}

.btn-other {
	background-color: #F7F7F7;
	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.45);
    width: 42px;
}
.btn-other.sm {
    width: 35px;
}
.btn-other:hover {
	background-color: #CCCCCC;
}

.input-group-btn .btn-tertiary,
.input-group-btn .btn-secondary,
.input-group-btn .btn-primary {
	border-radius: 0px;
	padding: 0px 10px;
}
.btn > .glyphicon {
	font-size: 13px;
	font-weight: bold;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
	opacity: .40;
	filter: alpha(opacity=40);
}

.input-group-btn .btn {
    box-shadow: none;
}

/* Selection Dropdown */
.selection.dropdown > button,
.selection.dropup > button {
	font-size: 16px;
}

.selection button.dropdown-toggle {
	padding-left: 20px;
	padding-right: 20px;
	text-align: left;
}

.selection button.dropdown-toggle,
.selection button.dropdown-toggle.btn:active,
.selection button.dropdown-toggle.btn:hover,
.selection button.dropdown-toggle.btn:active:hover,
.selection.open > button.dropdown-toggle.btn,
.selection.open > button.dropdown-toggle.btn:hover {
	background-color: #EDEDED;
	box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.45);
	border: 0;
}

.selection button.dropdown-toggle.btn-danger,
.selection button.dropdown-toggle.btn-danger:active,
.selection button.dropdown-toggle.btn-danger:hover,
.selection button.dropdown-toggle.btn-danger:active:hover,
.selection.open > button.dropdown-toggle.btn-danger,
.selection.open > button.dropdown-toggle.btn-danger:hover {
	color: #333333;
	border: 2px solid #C90202;
}

.selection a.dropdown-toggle {
	text-decoration: none;
	font-size: 24px;
}

.selection.dropdown > :focus,
.selection.dropup > :focus {
	outline: thin dotted;
}

.selection .dropdown-toggle-content {
	display: -webkit-flex;
	display: flex;
}

.selection .dropdown-toggle-caret-spacer {
	margin-left: 15px;
}

.selection.dropdown .caret,
.selection.dropup .caret {
	border-top: 11px dashed;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	border-bottom: none;
	-webkit-align-self: center;
	align-self: center;
	margin-left: auto;
}

.selection .dropdown-menu {
	min-width: 100%;
	padding: 0;
}

.selection .dropdown-menu-item-list {
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: left;
	max-height: 400px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.selection.open .dropdown-menu {
	display: block;
}

.selection .dropdown-menu-item-list > li > a,
.selection .dropdown-menu > .menu-footer {
	display: block;
	padding: 10px 20px 10px 20px;
	text-decoration: none;
	color: #333;
	white-space: nowrap;
}

.selection .dropdown-menu > .menu-footer {
	border-top: 3px solid #CCC;
}

.selection .dropdown-menu.bordered .dropdown-menu-item-list > li:not(.dropdown-header):not(.divider) + li:not(.dropdown-header):not(.divider):not(:first-child) {
	border-top: 1px solid #CCC;
}

.selection .dropdown-header {
	padding: 5px 20px;
	color: #333;
	background-color: #EDEDED;
}

.selection .dropdown-menu-item-list > li > a:hover,
.selection .dropdown-menu-item-list > li > a:focus {
	background-color: #F5F5F5;
}

.selection .dropdown-menu-item-list > li.active > a {
	background-color: #CCCCCC;
}

.selection button.dropdown-toggle.on-gray.btn {
	background-color: #FFF;
}

.selection button.dropdown-toggle.on-gray.btn:active {
	background-color: #FFF;
}

.selection button.dropdown-toggle.on-gray.btn:hover {
	background-color: #FFF;
}

.selection.open > button.dropdown-toggle.on-gray.btn:hover {
	background-color: #FFF;
}

.selection button.dropdown-toggle.on-gray.btn:active:hover {
	background-color: #FFF;
}

.selection.dropdown.link,
.selection.dropup.link {
	margin-left: 0;
}

.selection.dropdown.link > button,
.selection.dropup.link > button {
	background-color: #FFF;
	box-shadow: none;
	padding-left: 0;
}

.selection.dropdown.link.fi > button,
.selection.dropup.link.fi > button {
	color: #0055CC;
}

.selection.dropdown > button.btn-sm,
.selection.dropup > button.btn-sm,
.selection .dropdown-menu-sm {
	font-size: 14px;
}

/* DEPRECATED - dropdown styles that should be removed once Dropdown and Combobox are removed */
.di.dropdown {
	font-family: Arial;
	color: #333333;
	margin:5px 0px;
	display: inline-block;
}

.di.dropdown > button,
.di.dropdown button.toggle {
	background-color:#EDEDED;
	height:42px;
	text-align:left;
	border: 0px;
	padding-left: 20px;
	padding-right: 20px;
	box-shadow:1px 1px 1px 0px rgba(0,0,0,0.45);

}
.di.dropdown button:focus {
	outline: thin dotted;
}
.di.dropdown .ddlabel {

	margin-right: 20px;
	vertical-align: middle;
}

.di.dropdown .caret {
	border-top: 11px dashed;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	margin-left: 0px;
}
.di.dropdown .sort {
	width: 16px;
	height: 16px;
}

.di.dropdown > button .di-svg,
.di.dropdown .toggle .di-svg {
	color: inherit;
}

.di.dropdown.fi.link .sort {
	background-color: #0055CC;
}

.di.dropdown > ul{
	padding: 0px;
	font-size: 16px;
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.45);
}

@media (-ms-high-contrast: none) {
	/* IE11 specific box-shadow as they appear lighter and don't extend as far in IE11 as they do with other browsers */
	.di.dropdown > ul {
		box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.45);
	}
}

@supports (-ms-ime-align: auto) {
	/* Edge specific box-shadow as it appears lighter and doesn't extend on the sides very much */
	.di.dropdown > ul {
		box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.45);
	}
}

.di.dropdown > ul > li,
.input-group-btn .dropdown-menu li {
	min-height:60px;
	padding: 10px 20px 10px 20px;
	display: flex;
	align-items: center;
}

.di.dropdown > ul > li:hover,
.input-group-btn .dropdown-menu li:hover {
	background-color:#F5F5F5;
}
.di.dropdown > ul > li.active,
.input-group-btn .dropdown-menu li.active{
	background-color:#CCCCCC;
}
.di.dropdown > ul > li a,
.di.dropdown > ul > li a:hover {
	background-color: transparent !important;
	background-image: none !important;
	text-decoration: none;
	white-space: normal;
}

.di.dropdown.on-gray button{
	background-color:#FFF;
}
.di.dropdown.link {
	margin-left: 0px;
}

.di.dropdown.link > button{
	background-color:#FFF;
	box-shadow: none;
	padding-left: 0px;
}

.di.dropdown.link.fi > button {
	color: #0055CC;
}

.di.dropdown.link > ul {
	margin-top: -4px;
	margin-left: 0px;
}

.di.dropdown.sm > button {
	height:35px;
	font-size: 14px;
}

.di.dropdown.sm > ul {
    font-size: 14px;
}

/* Tab Overrides */
.tab-container {
	background-color: #ddd;
	padding: 0px;
}
.tab-container .nav>li>a {
    text-decoration: none;
    color:#333333;
    margin-right: 0px;
    border-right: 1px solid #acacac;
    border-bottom: 1px solid #acacac;
    padding: 10px 30px;
}

.tab-container .nav-tabs>li.active>a {
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-right: 1px solid #acacac;
}
.tab-container .dropdown-menu>li>a {
    text-decoration: none;

}
.tab-container .tab-pane {
    padding: 30px;
}

.tab-container .nav-tabs {
    border-bottom: 1px solid #acacac;
}
.tab-container .tab-content {
	background-color: #FFFFFF;
}
/** Any Thing expect X-Small*/
@media (min-width: 768px) {

}
/** X-Small Layout Overrides */
@media (max-width: 767px) {
    body {
        overflow-x: hidden; /* Bootstrap issue*/
    }
    .container {
        width: auto;
        padding-left: 0px;
        padding-right: 0px;
    }
    .tab-container .tab-pane{
        padding: 20px;
    }
    .tab-container .nav>li>a {
        padding: 10px 20px;
    }
    .tab-pane {
        padding: 20px;
    }

    body:not(.constant-font-size) {
        font-size: 14px;
    }
    h1:not(.constant-font-size), .h1:not(.constant-font-size) {
        font-size: 24px;
    }
    h2:not(.constant-font-size), .h2:not(.constant-font-size) {
        font-size: 22px;
    }
    h3:not(.constant-font-size), .h3:not(.constant-font-size) {
        font-size: 16px;
    }

    /* Model Dialog Overrides */
    .modal-dialog {
        margin: 0px 0px; /*Flush to device left, right*/
    }
    .modal-content {
        padding: 15px 15px 20px 15px;
    }

    .modal-header > .close {
        margin-top: -2px;
        margin-right: -1px;
    }

    /*Table overrides */
    .table-responsive .table {
        border-top: 0px solid #cccccc;
    }
    .table > tbody > tr > td {
        font-size: 14px;
    }
    .table > thead > tr > th{
		padding: 13px 7.5px;
	}
    .table > tbody > tr > td {
        padding: 17px 7.5px;
    }

    /** button */
    .btn-primary,
	.btn-secondary,
	.btn-tertiary {
        padding: 0px 15px;
    }
}
/** Small Layout Overrides */
@media (min-width: 768px) and (max-width: 991px) {
    .container {
        width: auto;
        padding-left: 0px;
        padding-right: 0px;
    }
    .tab-container .tab-pane{
        padding: 20px;
    }
    .tab-container .nav>li>a {
        padding: 10px 20px;
    }

    .tab-pane {
        padding: 20px;
    }

    /* Model Dialog Overrides */
    .modal-content {
        padding: 20px 20px 30px 20px;
    }
    .modal-header > .close {
        margin-top: -7px;
        margin-right: -6px;
    }

    /*Table overrides */
	.table > thead > tr > th{
		padding: 13px 10px;
	}
	.table > tbody > tr > td {
		padding: 17px 10px;
	}

}
/** Medium Layout overrides */
@media (min-width: 992px) and (max-width: 1199px) {
	.container {
		width: auto;
	}
}

/** Large Layout overrides */
@media (min-width: 1200px) {

}