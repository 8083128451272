.bordered-list > li {
  border-bottom: 1px solid #CCCCCC;
}

.bordered-list :last-child {
  border-bottom: none;
}

:global(.di.dropdown button.error) {
    border: 2px solid #c90202;
}

.base_dropdown {
  &:global(.di.dropdown) {
    display: block;
  }
  :global button .di-svg {
    float: right;
  }
}

.base_dropdown ul:global(.dropdown-menu) {
  margin-top: 0px;
  margin-left: 1px;
}