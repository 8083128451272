@import 'breakpoints';

#stepUpAuthenticationApp {
  min-height: 100%;
  position: relative;
}

#stepUpAuthenticationApp label{
  margin-top: 10px;
  margin-right: 10px;
}

#stepUpAuthenticationApp p {
  margin-right: 10px;
}

.mfa-dropdown-content {
  padding-left: 15px;
  padding-bottom: 30px;
}

#stepUpAuthenticationInput .form-group {
  margin-bottom: 10px !important;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-top-10 {
  margin-top: 10px;
}

.mfa-header-with-icon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.mfa-header-with-icon h2{
  flex-grow: 1;
}

.mfa-header-icon {
  display: inline-block;
  padding-top: 4px;
  padding-bottom: 10px;
  padding-right: 10px;
  font-size: 20px;
}

.mfa-phone-heading {
  padding: 0;
}

.di-accordion-selector{
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
}

.di-accordion-selector-label {
  display: inline-block;
  text-decoration: underline;
  font-size: 16px;
  //vertical-align: middle;
  margin-left: 25px;
}

.di-accordion-selector-icon:before {
  display: inline-block;
  margin-right: -20px;
  vertical-align: top;
  font-family: "Glyphicons Halflings";
  content:"\e250";
  width: 18px;
  font-size: 14px;

  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.di-accordion-selector-icon-opened:before {
  -moz-transform: rotate(90deg);
  -webkit-transform:rotate(90deg);
  -ms-transform:rotate(90deg);
  -o-transform:rotate(90deg);
}

.mfa-dropdown-content {
  padding-top: 5px;
  padding-left: 26px;
  padding-bottom: 1px;
  color: #333333;
}

.mfa-dropdown-link {
  margin-top: 8px;
  margin-bottom: 20px;
}

.mfa-dropdown-content {
  font-size: 16px;
}

.mfa-dropdown-content ul {
  margin-left: 20px;
}

.mfa-dropdown-content li {
  margin-top: 15px;
  list-style-type: square;
  font-size: 18px;
  line-height: 14px;
}

.mfa-dropdown-content div {
  font-size: 14px;
  vertical-align: middle;
}

.step-up-link {
  cursor: pointer;
  text-decoration: underline;
}

@media (min-width: $sm-min) {
  .header-description {
    margin-bottom: 20px;
  }

  .main-container {
    padding: 20px 0 20px 30px;
    background-color: #EDEDED;
  }

  .step-up-container {
    padding: 0;
    max-width: 838px;
  }

  .button-container {
    padding-right: 0;
  }

  .step-up-button {
    margin-left: 7px;
  }

  .cookie-buttons {
    padding-top: 10px;
  }

  .step-up-otp-button-container {
    padding-bottom: 10px;
  }

  .mfa-disclosure {
    margin-left: 10px;
    margin-right: 40px;
  }

  .fluid-aria-modal__dialog {
    min-width: 25% !important;
  }

  .step-up-loader {
    margin-left: 7px !important;
  }

  .step-up-phone {
    max-width: 64%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: $sm-max) {
    .step-up-phone {
      max-width: 46%;
    }

    .step-up-email {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media (max-width: $xs-max) {
  #stepUpAuthenticationInput {
    margin-left: 7px;
    margin-right: -7px;
  }

  .step-up-otp-container {
    margin-left: -1px !important;
  }

  .step-up-otp-button-container {
    margin-left: -1px !important;
  }

  .button-container button {
    width: 100%;
    margin: 5px 0;
  }

  .step-up-button {
    margin-left: 0 !important;
  }

  .step-up-transfer {
    padding: 0 15px;
  }
}
