// Wrapper style needed to hide the overflow and allow
// scrolling content in iOS devices. 
.iframe-overflow-wrapper {
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

// Required to fix LEAP-1100, where an extra bottom margin
// is added to the iframe which causes extra white background
// to appear when there shouldn't be one.
:global(.resizeIframe) {
	margin-bottom: -6px;
}

.iframe-webview-wrapper iframe {
	height: 100vh;
}