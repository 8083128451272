/* Form indenpendent 1 Component, ../react/components/calendar */

:global( .di-almanac ){
    min-width: 300px;
    background-color: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.45);
    color: #000;

    :global( .almanac-header ){
        overflow: auto;
        padding-top: 15px;
        padding-bottom: 20px;
        display: -webkit-flex;
        display: flex;

        :global( .section ){
            -webkit-flex: 1 0 auto;
                    flex: 1 0 auto;
            line-height: 15px;
            font-size: 18px;
        }

        :global( .month-label ){
            text-align: -webkit-center;
            text-align: center;
            vertical-align: middle;
            color: #05c;
        }

        :global( .icon ){
            display:inline-block;
            cursor: pointer;
        }
        :global( .icon.right ){
            float: right;
            margin-right: 15px;
        }

        :global( .icon.left ){
            float: left;
            margin-left: 15px;
        }
    }

    :global( .days ){
        width: 100%;
        
        :global( .loader-container ) {
            min-height: 200px;
            height: 200px;
            display: -webkit-flex;
            display: flex !important;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-flex-direction: column;
            flex-direction: column;
  
            :global( .loader-text ) {
                margin-top: 10px;
            }
        }
        
        :global( .list ){
            overflow: hidden;
            :global( .day-label ){
                padding-top: 10px;
                padding-bottom: 10px;
                width: 14.28%;
                text-align: center;
                float: left;
                color: #666666;
                line-height: 18px;
                list-style: none;
            }
        }
    }

    :global( .day ){
        width: 14.28%;
        line-height: 19px;
        height: 40px;
        float: left;
        position: relative;
        background-color: #fff;
        cursor: pointer;
        padding-top: 10px;

        :global( .no){
            min-width: 38px;
            display: inline-block;
            padding: 8px;
        }

        :global(.no:focus) {
            position: relative;
            z-index: 1;
            outline: thin dotted;
            outline: -webkit-focus-ring-color auto 5px;
        }
    }

    :global( .week ){
        vertical-align: middle;
        text-align: center;
        min-height: 40px;
    }

    :global( .footer.empty){
        padding-top: 40px;
    }

    :global( .day.selected.weekday.no),
    :global( .day.selectable.no:hover){
        border-radius: 18px;
        background-color: #2e6f03;
        color: #fff;
        outline-color: inherit;
    }

	:global(.day:focus){
		border: 1px dotted #000000;
	}
	
    :global( .day.current-day),
    :global( .day.selected ){
        font-size: 16px;
        border-radius: 18px;
        border: 1px solid #05c;
        //padding: 8px;
    }

    :global( .day.before-max-start),
    :global( .day.after-max-end),
    :global( .day.weekend),
    :global( .day.holiday ){
        cursor: not-allowed;
    }

    :global( .day.unselectable ),
    :global( .day.holiday ),
    :global( .day.weekend ) {
        color: #666666;
        background-color: #f2f2f2;
    }

    :global( .day.range .no){
        border-radius: 18px;
        border: 1px solid #cccccc;
        background-color: #cccccc;
        color: #000;
    }

    :global( .day.range.rn0 .no){
        border-radius: 18px;
        border: 1px solid #2e6f03;
        background-color: #2e6f03;
        color: #fff;
    }

    :global( .day.range.rnl .no){
        border-radius: 18px;
        border: 1px solid #d44403;
        background-color: #d44403;
        color: #ffffff;
    }

    // Case when Start & End Date is Same.
    :global( .day.range.rn0.rnl .no){
        border-radius: 18px;
        border: 1px solid #2e6f03;
        background-color: #d44403;
        color: #ffffff;
    }

}