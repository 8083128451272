@import './common/Breakpoints';

.slide-transition-dropdown {
	:global(.dropdown) {
	    margin: 0;
	    border: 1px solid #ccc;
	    position: static;
	    width: 100%;
	    :global(button.toggle) {
	    	width: 100%;
			padding-left: 10px;
			position: relative;
	    	:global(.di-svg) {
	    		float: right;
	    		top: 4px;
	    	}
	    }
	}

   :global(.input-group) {
      width:100%;
   }

	:global(.input-label) {
    	min-width: 80px;
    	text-align: left;
        border: 0px;
        padding: 0px;
		background: transparent;
		-webkit-align-self: center;
		align-self: center;
  	}

	:global(.di.dropdown.on-gray button) {
		box-shadow: none;
	}

	.slide-transition-dropdown-hidden-xs {
	    :global(.form-group) {
	        margin-bottom: 20px;
	        max-width: 400px;
	    }
    }

	ul {
		padding: 0;
		list-style: none;
		border-bottom: 1px solid #ccc;
	}

	:global(.btn-tertiary) {
		width: 100%;
		height: auto;
		border: 1px solid #ccc;
		border-bottom: none;
		border-radius: initial;
		text-align: left;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.di-arrange {
	    box-sizing: border-box;
	    display: table;
	    margin: 0;
	    min-width: 100%;
	    padding: 0;
	    table-layout: auto;
	}

	.di-arrange-fill {
    	width: 100%;
	}

	.di-arrange-fill, .di-arrange-fit {
    	display: table-cell;
    	padding: 0;
    	vertical-align: top;
	}

	.slide-transiton-dropdown-loading-div {
		margin-top: 5px;
	}

	.slide-transition-dropdown-error {
		label {
			color: #c90202;
		}
		button {
			border: solid 2px #c90202;
		}
	}

	.slide-transition-dropdown-error-div {
		margin-top: 5px;
	}
}

.slide-transition-dropdown button:hover {
	background-color: #f5f5f5;
}

@media (max-width: $xs-max) {
	.slide-transition-dropdown {
		:global(.input-label) {
			margin-left: 20px;
		}
	}
}