.slide-transition-datepicker-hidden-xs {
    :global(.form-control) {
        box-shadow: none;
        padding-left: 20px;
    }

    :global(.di-datepicker .form-group .form-control-feedback) {
    	right: 10px;
    }
}

.slide-transition-datepicker {
	:global(.input-group-addon) {
    	min-width: 80px;
    	text-align: left;
  	}

	.slide-transition-datepicker-error-div {
		margin-top: 5px;
	}    
}
