.parent-container {
	display: flex;
}

.parent-container-w-padding {
	display: flex;
	flex-direction: row-reverse;
	padding-left: 20px;
}

.main-area {
	font-family: "Source Sans 3", system-ui;
	display: flex;
	align-items: baseline;
	padding: 5px;
	margin: 5px 0 10px 0;
	border-radius: 5px;
	border: 1px solid #999;
	background-color: white;
}

.logo-area {
	margin-right: 5px;
}

.fdic-logo-img {
	align-content: flex-start;
	max-height: 25px;
}

.text-area {
	align-content: flex-start;
	font-size: 1.4rem;
	font-style: italic;
	font-weight: 400;
	color: #000;
}