@import "breakpoints";

/** Start of ExpandCollapse component styles **/

.expand-collapse {
  .content {
    margin-top: 20px;
  }

  .selector {
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
  }

  .selector-label {
    display: inline-block;
    text-decoration: underline;
    font-size: 16px;
  }
  .selector-label:focus{
    border: 1px dotted #333;
    outline: none;
  }

  .selector-icon:before {
    display: inline-block;
    margin-right: -20px;
    vertical-align: top;
    font-family: "Glyphicons Halflings";
    content:"\e250";
    width: 18px;
    font-size: 16px;

    -moz-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    margin-right: 4px;
  }

  .selector-icon-opened:before {
    -moz-transform: rotate(90deg);
    -webkit-transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    -o-transform:rotate(90deg);
  }
}

@media (max-width: $xs-max) {
    .expand-collapse {
        .selector-label {
            max-width: 92%;
        }
    }
}

/** End of ExpandCollapse component styles **/
